<template>
  <div id="app">
    <div class="container">
      <header>
        <div class="md-layout">
          <div class="md-layout-item md-size-10 md-xsmall-hide banner"></div>
          <div class="md-layout-item md-size-80 md-xsmall-hide banner"></div>
          <div class="md-layout-item md-size-10 md-xsmall-hide banner"></div>
          <div class="md-layout-item md-size-10 md-xsmall-size-5"></div>
          <div class="md-layout-item">
            <app-header />
          </div>
          <div class="md-layout-item md-size-10 md-xsmall-size-5"></div>
        </div>
      </header>

      <article>
        <div class="md-layout">
          <div class="md-layout-item md-size-10 md-xsmall-size-5"></div>
          <div class="md-layout-item">
            <div class="content">
              <router-view />
            </div>
          </div>
          <div class="md-layout-item md-size-10 md-xsmall-size-5"></div>
        </div>
      </article>
    </div>

    <footer>
      <div class="md-layout md-alignment-center-left">
        <div class="md-layout-item md-size-10 md-xsmall-size-5"></div>
        <div class="md-layout-item">
          <app-footer />
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";

export default {
  name: "app",
  components: {
      AppHeader,
      AppFooter
    }
};
</script>

<style lang="scss">
@import "./styles/_colors";

$footer-height: 40px;

#app {

  > .container {
    position: relative;
    min-height: 100vh;
  }

  header {
    > .md-layout {
      > .md-layout-item {
        padding: 20px 0 24px 0;
      }
    }
    .banner {
      height: 120px;
      background-color: #272740;
      &:nth-child(2) {
        background-image: url('assets/code-blue.png');
        background-repeat: space;
        background-size: 320px;
      }
    }
  }

  article {
    > .md-layout {
      > .md-layout-item {
        padding-bottom: 64px;
        .content {
          max-width: 800px;
          p {
            margin: 2px 0;
          }
          div p:first-of-type {
              margin-top: 14px;
          }
          div p:last-of-type {
              margin-bottom: 14px;
          }
        }
      }
    }
  }

  footer {
    display: block;
    margin-top: -$footer-height;
    width: 100%;
    background: $roelanddev-blue;
    color: #fff;
    font-size: 12px;
    > .md-layout {
      height: $footer-height;
    }
  }
}
</style>
