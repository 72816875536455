<template>
    <div>
        <span>KVK number: 75507722</span> <span class="dot"></span> <span>BTW number: NL002001382B50</span> 
    </div>
</template>

<script>

export default {
    name: "AppFooter"
}
</script>

<style lang="scss" scoped>
@import "@/styles/_colors";

div {
    span {
        white-space: nowrap;
    }
    .dot {
        height: 6px;
        width: 6px;
        background-color: $roelanddev-red;
        border-radius: 50%;
        display: inline-block;
        margin: 2px 8px;
    }
}
</style>
