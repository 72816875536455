import Vue from "vue";
import App from "./App.vue";
import { createRouter } from "./router";
import { MdButton, MdCard, MdContent, MdIcon } from "vue-material/dist/components";

import "vue-material/dist/vue-material.min.css";
import "./styles/theme.scss";

Vue.use(MdButton);
Vue.use(MdCard);
Vue.use(MdContent);
Vue.use(MdIcon);

export function createApp() {
  const router = createRouter();

  const app = new Vue({
    router,
    render: h => h(App)
  });

  return { app, router };
}
