import { createApp } from "./main";

// client-specific bootstrapping logic...
import "./registerServiceWorker";

const { app, router } = createApp();

router.onReady(() => {
   app.$mount("#app");
});
